import Api from "../Api";

const END_POINT = 'user/logout';

export default {
    logout(data) {
        return Api.post(END_POINT, data, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    }
}