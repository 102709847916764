import axios from "axios";

// const ApiBaseUrl = 'http://127.0.0.1:8081/api/v1/'
// const AppBaseUrl = 'http://127.0.0.1:8081/'

const ApiBaseUrl = 'https://ferreprod.ferrebaztan.com/FERREPROD-APP-BACK/public/api/v1/'
const AppBaseUrl = 'https://ferreprod.ferrebaztan.com/FERREPROD-APP-BACK/public/'

// baseURL: 'https://reqres.in/api'
// baseURL: 'ferreprod.ferrebaztan.com/FERREPROD-APP-BACK/public/api/v1/',

const Api = axios.create({
    baseURL: ApiBaseUrl,
    appBaseURL: AppBaseUrl,
    headers: {
        'Content-Type': 'application/json'
    }
});

export default Api;