/*
 * Main and demo navigation arrays
 */

export default {
    'main': [{
            name: 'Dashboard',
            to: '/backend/dashboard',
            icon: 'si si-speedometer'
        },
        {
            name: 'Configuracion',
            heading: true
        },
        {
            name: 'Usuarios',
            icon: 'si si-users',
            subActivePaths: '/backend/blocks',
            sub: [{
                name: 'Administrador',
                to: '/users_conf/admin',
                icon: 'fa fa-circle'
            }]
        },
        {
            name: 'Clientes',
            icon: 'fa fa-address-book',
            subActivePaths: '/backend/blocks',
            sub: [{
                    name: 'Consulta clientes',
                    to: '/clientes/list',
                    icon: 'fa fa-circle'
                },
                {
                    name: 'Tipos Clientes',
                    to: '/tipos_clientes/list',
                    icon: 'fa fa-circle'
                },
            ]
        },
        {
            name: 'Catalogos',
            icon: 'si si-list',
            subActivePaths: '/backend/blocks',
            sub: [{
                    name: 'Colaboradores',
                    icon: 'fa fa-users',
                    subActivePaths: '/backend/blocks',
                    sub: [{
                            name: 'Consulta empleados',
                            to: '/empleados/list',
                            icon: 'fa fa-circle'
                        },
                        {
                            name: 'Puesto',
                            to: '/puesto/list',
                            icon: 'fa fa-circle'
                        },
                        {
                            name: 'Area',
                            to: '/area/list',
                            icon: 'fa fa-circle'
                        },
                    ]
                },
                {
                    name: 'Maquinaria',
                    to: '/maquinaria/list',
                    icon: 'fa fa-cogs'
                },
                {
                    name: 'Ubicaciones',
                    icon: 'fas fa-map-marker-alt',
                    subActivePaths: '/backend/blocks',
                    sub: [{
                            name: 'Almacenes',
                            to: '/almacenes/list',
                            icon: 'fa fa-circle'
                        },
                        {
                            name: 'Sucursales',
                            to: '/sucursales/list',
                            icon: 'fa fa-circle'
                        }
                    ]
                },
                {
                    name: 'Producto Terminado',
                    icon: 'fa fa-truck',
                    subActivePaths: '/backend/blocks',
                    sub: [{
                            name: 'Consulta productos',
                            to: '/producto-terminado/list',
                            icon: 'fa fa-circle'
                        },
                        {
                            name: 'Familia',
                            to: '/producto-terminado/familia',
                            icon: 'fa fa-circle'
                        },
                        {
                            name: 'Unidades',
                            to: '/producto-terminado/unidades',
                            icon: 'fa fa-circle'
                        }
                    ]
                },
                {
                    name: 'Materia Prima',
                    icon: 'fas fa-mortar-pestle',
                    subActivePaths: '/backend/blocks',
                    sub: [{
                            name: 'Consulta Materia',
                            to: '/materia-prima/list',
                            icon: 'fa fa-circle'
                        },
                        {
                            name: 'Familia',
                            to: '/materia-prima/familia',
                            icon: 'fa fa-circle'
                        },
                        {
                            name: 'Unidades',
                            to: '/materia-prima/unidades',
                            icon: 'fa fa-circle'
                        },
                    ]
                },
                {
                    name: 'Servicios',
                    to: '/services/list',
                    icon: 'fa fa-file'
                }
            ]
        },
        {
            name: 'Cotizaciones',
            to: '/cotizacion/list',
            icon: 'fa fa-file'
        },
        {
            name: 'Órdenes de servicio',
            icon: 'si si-list',
            to: '/ordenes-de-servicio/list'
        },
        {
            name: 'Órdenes de producción',
            icon: 'si si-list',            
            to: '/ordenes-de-produccion/list'
        },
        {
            name: 'Hojas viajeras',
            icon: 'si si-list',
            subActivePaths: '/backend/blocks',
            to: '/hoja-viajera/list'
        },
        {
            name: 'Hojas de inspección',
            icon: 'si si-list',
            to: '/hojas-de-inspeccion/list'
        },
        {
            name: 'Hojas de impresión',
            icon: 'si si-list',
            to: '/hojas-de-impresion/list'
        },
        {
            name: 'Facturas',
            icon: 'si si-list',
            to: '/facturas/list'
        },
        {
            name: 'Costeo',
            icon: 'si si-list',
            to: '/costeo/list'
        },
        {
            name: 'Monitoreo',
            icon: 'si si-screen-desktop',
            subActivePaths: '/backend/blocks',
            sub: [
                {
                    name: 'Servicios',
                    to: '/status',
                    icon: 'fa fa-circle'
                },
                {
                    name: 'CRM',
                    to: '/crm',
                    icon: 'fa fa-circle'
                },
            ]
        }
    ]
}